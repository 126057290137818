<script setup lang="ts">
import type { IManagerFieldEmits, IManagerFieldProps } from './types'
import { useFieldCache, useFieldCalculation, useFieldCast } from '@manager'

const props = withDefaults(defineProps<IManagerFieldProps>(), {
  parentValue: () => ({}),
})
const emit = defineEmits<IManagerFieldEmits>()

const parentValue = useVModel(props, 'parentValue', emit)
const { cast, castPlugin } = useFieldCast(props.node)

// Cast value and fallback to default
parentValue.value[props.node.name] = cast(
  parentValue.value[props.node.name],
  props.node.defaultValue,
)

// Initialize calculated value, after setting default value
const { setCalculated } = useFieldCalculation(props.node, parentValue)

useFieldCache(props.node, parentValue)
</script>

<template>
  <ManagerField
    :node="node"
    :parent-node="parentNode"
    :parent-path="parentPath"
  >
    <template #default="{ id, name, label, validation, validationMessages }">
      <!-- TODO: Use `id` for `id`. Currently the FormKit `currency` input is breaking when using `id` -->
      <FormKit
        :id="name"
        v-model="parentValue[node.name]"
        type="currency"
        currency="AUD"
        display-locale="en-AU"
        decimals="2"
        :name="name"
        :label="label"
        label-class="overflow-ellipsis whitespace-nowrap overflow-hidden"
        :plugins="[castPlugin]"
        :validation="validation"
        :validation-messages="validationMessages"
        validation-visibility="live"
        :min="node.min ?? undefined"
        :max="node.min ?? undefined"
        @input="setCalculated"
      >
        <template v-if="node.suffix" #suffix>
          <span
            class="flex h-10 items-center rounded-r-[5px] bg-gray-700 px-2.5 pt-px text-xs"
          >
            {{ node.suffix }}
          </span>
        </template>
      </FormKit>
    </template>
  </ManagerField>
</template>
